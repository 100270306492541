import React from 'react';
import { navigate } from 'gatsby';
import * as styles from './sample.module.css';

import Blog from '../../components/Blog';
import Container from '../../components/Container';
import Layout from '../../components/Layout/Layout';
import Icon from '../../components/Icons/Icon';
import Button from '../../components/Button';

const SamplePage = (props) => {
  return (
    <Layout>
      <div className={styles.root}>
        <Container>
          <div className={styles.blogContainer}>
            <Blog
              category={'Lifestyle'}
              title={'Shelbizleee'}
              image={'/blogs/channels4_profile.jpg'}
              alt={''}
            >
              <div className={styles.content}>
                <p className={styles.excerpt}>
                Shelbizleee is an American YouTuber who has gained a following
                for her green minimalism content and research full and educational videos
                about greener living. With count of over 318'000 subscribers, her channel
                includes comentary on popular sustainability topics, greener choices for shopping/selfcase
                and most importantly motivational videos in support for greener living.
                </p>
              </div>
              <div className={styles.imagesContainer}>
                  <img src={'/blogs/shelbizleee-thumbnail.png'} alt={'picture'} />
              </div>
              <div className={styles.content}>
                <h2 className={styles.blogSubHeader}>ENG</h2>
                <p className={styles.blogParagraph}>
                  She is known for posting videos related to ecological aspect of clothing, self case, shopping and more.
                  In some videos she has stated that she is trying to follow minimalism also looking at sustainability aspects
                  of it. Her content is meant to inspire all of us to think about our ecological footprint and provide us with
                  suggestions of alternatives.
                </p>
                <p className={styles.blogParagraph}>
                  Her videos are edited more to seem like a chat with a friend as to show her idea to be more inviting and not as
                  educational program. Videos always contain plants and snippets of videos of her actually using the products of
                  approaches to show that she is sharing exactly what she is using her self.
                </p>
                <p className={styles.blogParagraph}>
                  See her content on YouTube: <a href="https://www.youtube.com/@Shelbizleee">Shelbizleee</a>
                </p>
              </div>
              <div className={styles.content}>
                <br></br>
                <h2 className={styles.blogSubHeader}>LV</h2>
                <p className={styles.blogParagraph}>
                Shelbizleee ir amerikāņu YouTube personība, kura ir ieguvis sekotājus
                 par viņas zaļo minimālisma saturu un izpētes pilniem un izglītojošiem video
                 par zaļāku dzīvi. Viņas kanālam ir vairāk nekā 318'000 abonentu
                 ietver komentārus par populārām ilgtspējības tēmām, videi nekaitīgākām iepirkšanās iespējām
                 un, pats galvenais, motivējošiem videoklipiem zaļākas dzīves atbalstam.
                </p>
                <p className={styles.blogParagraph}>
                Viņa ir pazīstama ar to, ka publicē videoklipus, kas saistīti ar apģērba ekoloģisko aspektu, iepirkšanos un daudz ko citu.
                   Dažos videoklipos viņa ir norādījusi, ka cenšas ievērot minimālismu, aplūkojot arī ilgtspējības aspektus
                   no tā. Viņas saturs ir paredzēts, lai iedvesmotu mūs visus domāt par savu ekoloģisko ietekmi un sniegtu mums to
                   alternatīvu ierosinājumus.
                </p>
                <p className={styles.blogParagraph}>
                Viņas videoklipi ir vairāk rediģēti, lai tie liktos kā tērzēšana ar draugu, lai padarītu viņas ideju aicinošāku, nevis kā
                   izglītības programmu. Videoklipos vienmēr ir iekļauti augi un videoklipu fragmenti, kuros viņa faktiski izmanto produktus un
                   pieejas, lai parādītu, ka viņa dalās tieši tajā, ko izmanto pati.
                </p>
                <p className={styles.blogParagraph}>
                Skatiet viņas saturu pakalpojumā YouTube: <a href="https://www.youtube.com/@Shelbizleee">Shelbizleee</a>
                </p>
              </div>
            </Blog>
          </div>
          <div className={styles.footerContainer}>
        <span>Find her content on:</span>
        <div className={styles.socialMediaListContainer}>
          <div className={styles.socialMediaIconContainer}>
            <Icon symbol={'twitterinverse'}></Icon>
          </div>
          <div className={styles.socialMediaIconContainer}>
            <Icon symbol={'facebookinverse'}></Icon>
          </div>
         {/*<div className={styles.socialMediaIconContainer}>
            <Icon symbol={'pinterestinverse'}></Icon>
</div> */}
          <div className={styles.socialMediaIconContainer}>
            <Icon symbol={'youtube'}></Icon>
          </div>
        </div>
        <Button onClick={() => navigate('/')} level={'secondary'}>
          back to summaries
        </Button>
      </div>
        </Container>
      </div>
    </Layout>
  );
};

export default SamplePage;
